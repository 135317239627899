/**
 * Type: テンプレート
 * What: 投稿詳細
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import { Btn, Button, Text, Title } from '../components/Btn';
import HtmlEscape from '../util/htmlEscape';

const pageSlug = 'post';

const removeP = (val) => {
  const preRemove = val.split('<p>');
  const remove = preRemove[1].split('</p>');
  return remove[0];
};

export const BlogPostTemplate = ({
  content,
  excerpt,
  categories,
  title,
  date,
  baseUrl,
  featuredMedia,
}) => (
  <>
    <SEO title={title} description={excerpt ? removeP(excerpt) : ''} />
    <BreadcrumbComponent
      hierarchy={3}
      bread2title="ニュース｜新着情報一覧"
      bread2url={baseUrl}
      bread3title={title}
    />
    <section className="section" id="postDetail">
      <div className="container">
        <h2 className="headline">
          <span>Topics</span>
        </h2>
        {/* featuredMedia && (
          <div className="post-kv">
            <img src={featuredMedia.source_url} alt={title} />
          </div>
        ) */}
        <div className="border-box">
          <div className="post-date sp">{date}</div>
          <h3 className="headline title">
            <span
              dangerouslySetInnerHTML={{
                __html: HtmlEscape(title),
              }}
            />
          </h3>
          <div className="post-info">
            <div className="post-tags">
              {categories && categories.length ? (
                <div className="tags are-normal">
                  {categories.map((category) => (
                    <Link
                      to={`/news/${category.slug}/`}
                      key={`${category.slug}cat`}
                      className="tag is-link"
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="post-date pc">{date}</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: HtmlEscape(content) }}
            className="entry-content"
          />
        </div>
      </div>
      <div className="btn-wrap">
        <Button href="/news/">一覧をみる</Button>
      </div>
    </section>
  </>
);

BlogPostTemplate.defaultProps = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { wordpressPost: post, site } = data;

  return (
    <Layout pageSlug={pageSlug}>
      <BlogPostTemplate
        content={post.content}
        excerpt={post.excerpt}
        categories={post.categories}
        featuredMedia={post.featured_media}
        title={post.title}
        date={post.date}
        baseUrl={site.siteMetadata.routingSlug.posts}
      />
    </Layout>
  );
};

BlogPost.defaultProps = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.string,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    featured_media {
      source_url
    }
    date(formatString: "YYYY.MM.DD")
    title
    routingSlug {
      posts
    }
  }
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
        routingSlug {
          posts
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      featured_media {
        source_url
      }
      date(formatString: "YYYY.MM.DD")
      categories {
        name
        slug
      }
    }
  }
`;
